/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/axios@0.21.4/dist/axios.min.js
 * - /npm/hls.js@1.6.2/dist/hls.light.min.js
 * - /npm/plyr@3.7.8/dist/plyr.min.js
 * - /npm/vue@2.7.16/dist/vue.runtime.min.js
 * - /npm/vue-carousel@0.18.0/dist/vue-carousel.min.js
 * - /npm/vue-plyr@7.0.0/dist/vue-plyr.min.js
 * - /npm/vue-router@3.6.5/dist/vue-router.min.js
 * - /npm/mdui@1.0.2/dist/js/mdui.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
